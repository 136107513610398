<template>
  <div v-if="scope.row.scale_type">
    <div
      class="element-scale-config"
      :class="{editable: isFieldAvailableForEdit(scope.row, 'scale')}"
      @click="$refs.editScaleDialog.open(scope.row)"
    >
<!--        :data="scope.row.scale"-->
<!--        :scale-name="scope.row.scale_type"-->
<!--        :scale-config="scaleEditConfig"-->
      <component
        :is="scaleTemplate"
      ></component>
    </div>
<!--    <card-element-scale-edit-dialog-->
<!--      ref="editScaleDialog"-->
<!--      @save-clicked="saveElementScaleData"-->
<!--    >-->
<!--    </card-element-scale-edit-dialog>-->
  </div>
  <div v-else style="color: #b1b1b2;">Сначала укажи тип цели</div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import CardElementScaleEditDialog from "@/components/cards/CardElementsBlock/CardElementScaleEditDialog";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-configurable-scale-config-table-column',
  mixins: [ElementPropTableColumn],
  components: {CardElementScaleEditDialog, ClickToEdit},

  props: {},
  data() {
    return {}
  },
  computed: {
    scaleTemplate() {
      if( !this.scope.row?.scale?.template?.type ){
        return null;
      }
      return this.$companyConfiguration.scales.getConfigurableScaleTemplate(this.scope.row.scale.template.type, 'cardScaleView');
    },

    scaleEditConfig() {
      if( !this.scope.row.scale_type ){
        return null;
      }
      return this.$companyConfiguration.scales.getScaleConfig(this.scope.row.scale_type);
    },
  },
  methods: {
    saveElementScaleData(data){
      this.saveElementPropChange(this.scope.row, 'scale', data.scale)
        .finally(() => {
          this.$refs.editScaleDialog.close();
        })
    }
  }
}

</script>

<style lang="scss">
@import "@/assets/css/project-variables";

.element-scale-config {
  padding: 2px 5px;
  cursor: pointer;

  &.editable {
    border: $--editable-border;
    border-radius: $--border-radius-base;
  }

  .card-view-divider {
    margin:0;
  }
}
</style>
